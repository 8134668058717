import { ChevronDown, ContentLoader, DialogClose, DialogDescription, DialogTitle, Form, FormField, FormItem, FormLabel, FormMessage, useI18n, useStocks, } from "@jog/react-components";
import { useMemo, useState } from "react";
import { FormSelect } from "../FormField";
import { FormInput } from "../FormField/FormInput";
import { Search } from "../Icon/Search";
import { ActiveStore } from "./ActiveStore";
import { StoreItem } from "./StoreItem";
export const StoreStocksSearch = ({ sizes, detail, }) => {
    var _a;
    const { $t } = useI18n();
    const skus = useMemo(() => {
        switch (sizes.type) {
            case "size":
                return sizes.sizes.map((i) => ({ sku: i.sku, size: i.size }));
            case "waist_length":
                return sizes.sizes.map((i) => ({
                    sku: i.sku,
                    size: `${i.waist}/${i.length}`,
                }));
            default:
                return [];
        }
    }, [sizes]);
    const { pending, stores, stocks, form, onSubmit, onMyLocation } = useStocks();
    const sku = form.watch("sku");
    const address = form.watch("address");
    const size = ((_a = skus.find((i) => i.sku === sku)) === null || _a === void 0 ? void 0 : _a.size) || "";
    const [activeStore, setActiveStore] = useState(null);
    return (<Form {...form}>
            <form onSubmit={onSubmit} className="flex h-full flex-col overflow-hidden py-6">
                <div className="mb-4 shrink-0 border-b border-grey-light px-5 pt-6 pb-4 lg:px-8">
                    {!!activeStore && (<button onClick={() => setActiveStore(null)} className="-mt-5 mb-5 flex items-center font-bold uppercase tracking-8 text-grey-darker hover:text-orange-main">
                            <ChevronDown className="rotate-90" strokeWidth="1"/>
                            {$t("back")}
                        </button>)}
                    <DialogTitle asChild>
                        <h2 className="text-[25px] font-semibold tracking-3 text-black">
                            {activeStore ? $t("Store detail") : $t("Show store stock")}
                        </h2>
                    </DialogTitle>
                    <DialogDescription asChild>
                        <p className="text-sm tracking-3 text-black">
                            {stores.length > 0
            ? $t("Do you want to pick up the {name} ({slug}) in the store? Please contact the nearest Jeans Center store and book.", { name: detail.name, slug: detail.slug })
            : $t("Do you want to know if this item is in stock at your nearest Jeans Center Store? View it below.")}
                        </p>
                    </DialogDescription>
                </div>
                <div className="flex-1 overflow-auto px-5 lg:px-8">
                    {activeStore ? (<div className="space-y-5">
                            <ActiveStore activeStore={activeStore}/>
                            <div>
                                <DialogClose asChild>
                                    <button type="button" className="w-[240px] border border-green-dark bg-green-dark p-3 text-center text-base font-semibold uppercase tracking-8 text-white hover:border-orange-main hover:bg-orange-main disabled:cursor-not-allowed">
                                        {$t("continue shopping")}
                                    </button>
                                </DialogClose>
                            </div>
                        </div>) : (<div className="flex h-full flex-col space-y-6">
                            <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                                <FormField control={form.control} name="address" render={({ field }) => (<FormItem>
                                            <div className="relative w-full">
                                                <FormInput placeholder={$t("Enter a zip code or city name")} className="w-full py-3 pl-4 pr-3 leading-6" {...field}/>
                                                <FormLabel className="absolute right-4 top-1/2 -translate-y-1/2">
                                                    <Search width={18} height={18}/>
                                                </FormLabel>
                                            </div>
                                            <FormMessage className="mt-1.5 italic" Render={({ msg }) => <>{$t(msg)}</>}/>
                                        </FormItem>)}/>
                                <FormField control={form.control} name="sku" render={({ field }) => (<FormItem>
                                            <div className="w-full">
                                                <FormSelect className="z-10 py-3 pl-4 pr-3 font-medium [&_svg]:h-6 [&_svg]:w-6" defaultValue={field.value} onValueChange={(v) => {
                    field.onChange(v);
                    if (address) {
                        onSubmit();
                    }
                }} name={field.name} options={skus.map((i) => ({ value: i.sku, text: i.size }))} placeholder={$t("Select your size")}/>
                                            </div>
                                            <FormMessage className="mt-1.5 italic" Render={({ msg }) => <>{$t(msg)}</>}/>
                                        </FormItem>)}/>
                            </div>
                            {pending ? (<div>
                                    <ContentLoader height="100px"/>
                                    <ContentLoader height="100px"/>
                                </div>) : stores.length > 0 ? (<ul className="space-y-4">
                                    {stores.map((store) => (<StoreItem key={store.storeNumber} store={store} stock={stocks[store.storeNumber]} size={size} onClick={() => setActiveStore(store)}/>))}
                                </ul>) : (<></>)}
                            <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                                <button disabled={pending} className="border border-green-dark bg-green-dark p-3 text-center text-base font-semibold uppercase tracking-8 text-white hover:border-orange-main hover:bg-orange-main disabled:cursor-not-allowed" type="submit">
                                    {$t("Find a store")}
                                </button>
                                <button type="button" onClick={onMyLocation} disabled={pending} className="border border-green-dark p-3 text-center text-base font-semibold uppercase tracking-8 text-green-dark hover:border-orange-main hover:bg-orange-main hover:text-white disabled:cursor-not-allowed">
                                    {$t("Use current location")}
                                </button>
                            </div>
                            <div className="text-center text-xs tracking-3 text-grey-medium">
                                {$t("Discover availability in the store")}
                            </div>
                        </div>)}
                </div>
            </form>
        </Form>);
};
