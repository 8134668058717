export const TickYes = (props) => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="15" height="13" viewBox="0 0 15 13" fill="currentColor" {...props}>
            <path fillRule="evenodd" clipRule="evenodd" d="M14.8682 2.05218L12.6799 0L4.84955 8.38196L2.12132 5.70729L0 7.82861L4.94975 12.7784L4.9668 12.7478L4.98438 12.7656L14.8682 2.05218Z"/>
        </svg>);
};
export const TickNo = (props) => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="14" height="13" viewBox="0 0 14 13" fill="currentColor" {...props}>
            <path fillRule="evenodd" clipRule="evenodd" d="M12.8683 2.05218L10.68 0L6.75431 4.20226L2.69749 0.211199L0.576172 2.33252L4.68102 6.4216L0.812611 10.5625L2.98449 12.7656L6.84683 8.57909L10.8167 12.5337L13.0884 10.4336L8.91955 6.3324L12.8683 2.05218Z"/>
        </svg>);
};
export const Tick = ({ status, ...props }) => {
    return status ? <TickYes {...props}/> : <TickNo {...props}/>;
};
