import { zodResolver } from "@hookform/resolvers/zod";
import { getStockByStoreIds } from "@jog/shared";
import { useCallback, useState } from "react";
import { z } from "zod";
import { useForm } from "../ui";
import { getStoreLocator, useUserLocation } from "./useLocation";
const searchSchema = z.object({
    sku: z.string().min(1, { message: "Select your size" }),
    address: z.string().refine((value) => {
        if (value.length < 3)
            return false;
        if (/\d/.test(value)) {
            if (/^[1-9][0-9]{3}\s?[a-zA-Z]{2}$/.test(value))
                return true;
            else if (/^[1-9][0-9]{3}$/.test(value))
                return true;
            else
                return false;
        }
        return true;
    }, { message: "Enter a correct zip code" }),
});
export const useStocks = () => {
    const { getLocation } = useUserLocation();
    const [stores, setStores] = useState({ list: [], search: "" });
    const [stocks, setStocks] = useState({});
    const [pending, setPending] = useState(false);
    const form = useForm({
        resolver: zodResolver(searchSchema),
        defaultValues: { sku: "", address: "" },
    });
    const sku = form.watch("sku");
    const onSubmit = form.handleSubmit(async (data) => {
        if (pending)
            return;
        setPending(true);
        try {
            const jcStores = data.address === stores.search
                ? stores.list
                : await getStoreLocator(data.address).then((res) => res.filter((i) => i.storeType === "Jeans Centre").slice(0, 5));
            const stocks = await getStockByStoreIds(data.sku, jcStores.map((i) => i.storeNumber));
            setStocks(stocks);
            setStores({ list: jcStores, search: data.address });
        }
        catch (error) {
            form.setError("address", { message: "Something went wrong" });
            console.error(error);
            setStocks({});
            setStores({ list: [], search: "" });
        }
        finally {
            setPending(false);
        }
    });
    const onMyLocation = useCallback(async () => {
        const city = await getLocation().catch((e) => {
            console.log("error", e);
            form.setError("address", { message: "User denied Geolocation" });
        });
        if (!city)
            return;
        form.setValue("address", city);
        if (sku)
            onSubmit();
    }, [getLocation, form, onSubmit, sku]);
    return {
        pending,
        stores: stores.list,
        stocks,
        form,
        onSubmit,
        onMyLocation,
    };
};
