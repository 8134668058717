import { Breadcrumbs } from "@/components";
import { Pdp } from "@/components/Pdp";
import Layout from "@/layout";
import { PageNotFound } from "@/lib/notFound";
import { buildPageProps } from "@/lib/ssg/slug";
import { createClient } from "@jog/ct";
import { PdpProvider, usePdpBreadcrumb } from "@jog/react-components";
import { buildProductDetailInfo, fetchS3Brands, fetchS3BreadcrumbSpecialCategory, fetchS3Pdp, fetchS3PdpSizes, prismicClient, retry, transBrands, transBreadcrumbSpecialCategory, transPdp, transPdpSizes, } from "@jog/shared";
export const buildProductPage = async (redisItem, pageContext) => {
    const { deps, locale, ...others } = redisItem;
    const ctClient = createClient();
    const product = await retry(() => ctClient.product({ id: deps.ct.id, locale1: "en", locale2: locale }).then((res) => res.product));
    if (!product)
        throw new Error(`Not found product from ${redisItem.path}`);
    const previewData = pageContext.previewData;
    const prismic = await retry(() => previewData ? prismicClient({ previewData }).getSingle("pdp") : fetchS3Pdp()).then((res) => transPdp(res.data));
    const pdpSizesJson = await retry(() => previewData ? prismicClient({ previewData }).getSingle("pdp_sizes") : fetchS3PdpSizes()).then((res) => transPdpSizes(res.data));
    const brandsJson = await retry(() => previewData ? prismicClient({ previewData }).getSingle("brands") : fetchS3Brands()).then((res) => transBrands(res.data));
    const breadcrumbSpecialCategory = await retry(() => previewData
        ? prismicClient({ previewData }).getSingle("breadcrumb_special_category")
        : fetchS3BreadcrumbSpecialCategory()).then((res) => transBreadcrumbSpecialCategory(res.data.breadcrumb_special_category));
    return buildPageProps(pageContext, {
        locale,
        ...others,
        productDetailInfo: buildProductDetailInfo({ product, prismic, pdpSizesJson, brandsJson }, locale.toLowerCase()),
        breadcrumbSpecialCategory,
        prismic: { ...prismic, sustainable: [] },
    });
};
export const ProductPageContent = ({ page }) => {
    const list = usePdpBreadcrumb(page.path.split("/"), page.breadcrumbSpecialCategory);
    if (!page.prismic || !page.productDetailInfo || !page.productDetailInfo.published)
        return <PageNotFound />;
    return (<Layout gtmTypeOfPage="pdp">
            <PdpProvider key={page.path} detail={page.productDetailInfo} pdp={page.prismic}>
                <Breadcrumbs list={list}/>
                <Pdp />
            </PdpProvider>
        </Layout>);
};
