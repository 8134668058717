import { Marketing } from "@/components/Marketing";
import Layout from "@/layout";
import { getNotFoundProps } from "@/lib/notFound";
import { getFeaturedProducts } from "@/lib/prismicio/featuredProducts";
import { fetchMarket } from "@/lib/prismicio/market";
import { buildPageProps } from "@/lib/ssg/slug";
import { FeaturedProductsContext } from "@jog/react-components";
import { fetchS3ContentPage, retry } from "@jog/shared";
export const buildMarketPage = async (redisItem, pageContext) => {
    const { deps, ...others } = redisItem;
    if (!redisItem.deps) {
        return getNotFoundProps(pageContext);
    }
    const prismic = await retry(() => pageContext.preview && pageContext.previewData
        ? fetchMarket(deps.uid, pageContext.previewData)
        : fetchS3ContentPage("marketing_content", deps.uid));
    const featuredProducts = await getFeaturedProducts(prismic, others.locale);
    return buildPageProps(pageContext, { prismic, ...others }, featuredProducts);
};
export const MarketingPageContent = ({ page, featuredProducts, }) => {
    return (<Layout>
            <FeaturedProductsContext.Provider value={{ products: featuredProducts }}>
                <Marketing prismic={page.prismic.data}/>
            </FeaturedProductsContext.Provider>
        </Layout>);
};
