import { ChevronDown, Tick, cn, useI18n } from "@jog/react-components";
import { typedBoolean } from "@jog/shared";
import { LocationIcon } from "../Icon/LocationIcon";
export const StoreItem = ({ store, stock, size, onClick, }) => {
    const { $t } = useI18n();
    return (<li onClick={onClick} className={cn("group cursor-pointer space-y-4 border-b p-4 text-base tracking-8", "bg-gray-bg text-black hover:bg-gray-base hover:text-white")}>
            <div className="flex items-center justify-between">
                <div className="font-bold uppercase">{store.town}</div>
                <div className="ml-1 flex items-center font-medium">
                    <LocationIcon fill="currentColor" className="mr-1" width={20} height={20}/>
                    {store.formattedDistance}
                </div>
            </div>
            <div className="font-medium">
                <div>{store.address}</div>
                <div>{[store.postalCode, store.town].filter(typedBoolean).join(" ")}</div>
                <div className="underline">
                    Tel:
                    <a href={`tel:${store.phone}`}>{store.phone}</a>
                </div>
            </div>
            <div className="flex flex-col space-y-4 font-medium lg:flex-row lg:items-center lg:justify-between lg:space-y-0">
                <div className="flex items-center space-x-2">
                    <Tick status={stock > 0}/>
                    <span>{$t("{stock} in stock (size {size})", { stock: `${stock}`, size })}</span>
                </div>
                <div className="flex justify-end">
                    <button className="flex items-center font-medium" type="button">
                        <span className="tracking-8 underline">{$t("View store")}</span>
                        <ChevronDown className="-rotate-90" strokeWidth="1"/>
                    </button>
                </div>
            </div>
        </li>);
};
