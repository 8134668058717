import * as DialogPrimitive from "@radix-ui/react-dialog";
import * as React from "react";
import { cn } from "./util";
const Dialog = DialogPrimitive.Root;
const DialogTrigger = DialogPrimitive.Trigger;
const DialogPortal = DialogPrimitive.Portal;
const DialogClose = DialogPrimitive.Close;
const DialogOverlay = React.forwardRef(({ className, ...props }, ref) => (<DialogPrimitive.Overlay ref={ref} className={cn("fixed inset-0 z-50 bg-[#C0C0C0]/80", className)} {...props}/>));
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;
const DialogPureContent = React.forwardRef(({ className, children, ...props }, ref) => (<DialogPortal>
        <DialogOverlay />
        <DialogPrimitive.Content ref={ref} className={cn("fixed inset-0 z-50 flex w-full flex-col border bg-white shadow-dialog duration-200 lg:right-auto lg:bottom-auto lg:left-[50%] lg:top-[50%] lg:max-h-[80%] lg:max-w-2xl lg:translate-x-[-50%] lg:translate-y-[-50%]", className)} {...props}>
            {children}
        </DialogPrimitive.Content>
    </DialogPortal>));
DialogPureContent.displayName = DialogPrimitive.Content.displayName;
const DialogContent = React.forwardRef(({ className, children, ...props }, ref) => (<DialogPortal>
        <DialogOverlay />
        <DialogPrimitive.Content ref={ref} className={cn("fixed left-[50%] top-[50%] z-50 w-full max-w-lg translate-x-[-50%] translate-y-[-50%] border bg-white shadow-dialog duration-200", className)} {...props}>
            {children}
            <DialogPrimitive.Close className="absolute right-6 top-4 rounded-sm opacity-70 transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:pointer-events-none">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23" viewBox="0 0 24 23" fill="none">
                    <line y1="-1" x2="30.4795" y2="-1" transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 0 21.5522)" stroke="#292E33" strokeWidth="2"/>
                    <line x1="22.8451" y1="22.2594" x2="1.29288" y2="0.707091" stroke="#292E33" strokeWidth="2"/>
                </svg>
                <span className="sr-only">Close</span>
            </DialogPrimitive.Close>
        </DialogPrimitive.Content>
    </DialogPortal>));
DialogContent.displayName = DialogPrimitive.Content.displayName;
const DialogTitle = DialogPrimitive.Title;
const DialogDescription = DialogPrimitive.Description;
const DialogHeader = ({ className, ...props }) => (<div className={cn("flex flex-col", className)} {...props}/>);
const DialogFooter = ({ className, ...props }) => (<div className={cn("flex flex-col", className)} {...props}/>);
export { Dialog, DialogPortal, DialogOverlay, DialogTrigger, DialogClose, DialogContent, DialogTitle, DialogDescription, DialogHeader, DialogFooter, DialogPureContent, };
