import { AccordionContent, AccordionItem, AccordionRoot, AccordionTrigger, useI18n, usePdp, } from "@jog/react-components";
import { replacePlaceholderInUrl } from "@jog/shared";
import { isFilled } from "@prismicio/helpers";
import { PrismicLink, PrismicRichText } from "@prismicio/react";
const RichText = ({ field }) => {
    return (<PrismicRichText field={field} components={{
            paragraph: ({ children, node, text }) => {
                if (!node.spans.length && !text) {
                    return (<p>
                                <br />
                            </p>);
                }
                return <p>{children}</p>;
            },
            hyperlink: ({ node, children }) => {
                if (node.data.url) {
                    node.data.url = replacePlaceholderInUrl(node.data.url).replace(process.env.NEXT_PUBLIC_SITE_URL || "", "");
                    node.data.url = node.data.url.replace(/https:\/\/tel*:/, "tel:");
                    node.data.url = node.data.url.replace(/https:\/\/mailto:/, "mailto:");
                }
                const target = node.data.link_type === "Web" && node.data.target ? node.data.target : "_self";
                return (<PrismicLink field={node.data} target={target} className="underline">
                            {children}
                        </PrismicLink>);
            },
        }}/>);
};
export const ExtraProductInfo = () => {
    const { detail } = usePdp();
    const { $t } = useI18n();
    if (!isFilled.richText(detail.brand.brandInfo))
        return <></>;
    return (<div className="border-t border-black px-4 py-6 lg:px-2" id="extraProductInfo">
            <AccordionRoot type="single" collapsible defaultValue="">
                <AccordionItem value="extraProductInfo">
                    <AccordionTrigger>{$t("Extra product information")}</AccordionTrigger>
                    <AccordionContent>
                        <div className="text-sm leading-5 text-black">
                            <RichText field={detail.brand.brandInfo}></RichText>
                        </div>
                    </AccordionContent>
                </AccordionItem>
            </AccordionRoot>
        </div>);
};
