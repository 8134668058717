import { Container, ErrorBoundary, Head } from "@/components";
import { Sidebar } from "@/components/Service/Sidebar";
import { SliceRender } from "@/components/Service/Slice";
import { asText, isFilled } from "@prismicio/helpers";
const ServiceNavigation = ({ prismic, className }) => {
    var _a;
    if ((_a = [...prismic.body]) === null || _a === void 0 ? void 0 : _a.some((i) => i.slice_type === "service_page_navigation")) {
        return (<div className={className}>
                {[...prismic.body].map((slice, index) => {
                if (slice.slice_type !== "service_page_navigation")
                    return <></>;
                return <Sidebar items={slice.items} key={index} title={slice.primary.navigation_title}/>;
            })}
            </div>);
    }
    if (isFilled.contentRelationship(prismic.service_navigation)) {
        return (<div className={className}>
                {[...prismic.service_navigation.data.body].map((slice, index) => (<Sidebar key={index} items={slice.items} title={slice.primary.navigation_title}/>))}
            </div>);
    }
    return <></>;
};
export const Service = ({ prismic }) => {
    return (<ErrorBoundary system="service">
            <Head title={asText(prismic.meta_tag_title)} robots={asText(prismic.meta_tag_robots)} description={asText(prismic.meta_tag_description)}/>
            <Container className="mb-17.5">
                <div className="grid grid-cols-12 gap-4">
                    <div className="col-span-3 hidden lg:block">
                        <ServiceNavigation prismic={prismic} className="bg-white p-6.25"/>
                    </div>
                    <div className="col-span-12 lg:col-span-9">
                        <SliceRender slices={prismic.body}/>
                    </div>
                </div>
            </Container>
        </ErrorBoundary>);
};
