import { Dialog, DialogClose, DialogPureContent, DialogTrigger, useGate, useI18n, usePdp } from "@jog/react-components";
import { Cross } from "../Icon/Cross";
import { StoreStocksSearch } from "../StoreStocks/Search";
export const StockDialog = () => {
    const { $t } = useI18n();
    const { productData, detail } = usePdp();
    const { value, isLoading } = useGate("pdp_stock_dialog");
    if (!productData)
        return <></>;
    if (isLoading)
        return <></>;
    if (!value)
        return <></>;
    return (<Dialog>
            <DialogTrigger asChild>
                <button id="stock-dialog" className="cursor-pointer text-left text-sm font-normal tracking-2 text-black underline">
                    {$t("View store stock")}
                </button>
            </DialogTrigger>
            <DialogPureContent>
                <StoreStocksSearch sizes={productData.sizeGroup} detail={detail}/>

                <DialogClose className="absolute right-3 top-4 flex h-10 w-10 items-center justify-center bg-green-main hover:bg-orange-main">
                    <Cross width="32" height="32" className="fill-current text-white"/>
                    <span className="sr-only">Close</span>
                </DialogClose>
            </DialogPureContent>
        </Dialog>);
};
