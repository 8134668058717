import { CheckMarks } from "@/components/JcStore/CheckMark";
import { StoreWorkTime } from "@jog/react-components";
import { typedBoolean } from "@jog/shared";
export const ActiveStore = ({ activeStore }) => {
    return (<div className="grid grid-cols-2 gap-y-6 gap-x-6 bg-gray-bg px-4 py-7 tracking-8 text-black">
            <h3 className="col-span-full font-bold uppercase">
                {[activeStore.storeType, activeStore.town].filter(typedBoolean).join(" ")}
            </h3>
            <div className="col-span-full lg:col-span-1">
                <div className="font-medium">
                    <div>{activeStore.address}</div>
                    <div>{[activeStore.postalCode, activeStore.town].filter(typedBoolean).join(" ")}</div>
                    <div className="underline">
                        Tel:
                        <a href={`tel:${activeStore.phone}`}>{activeStore.phone}</a>
                    </div>
                </div>
                <CheckMarks className="mt-4" store={activeStore}/>
            </div>
            <div className="col-span-full lg:col-span-1">
                <StoreWorkTime openings={activeStore.openings}/>
            </div>
        </div>);
};
